
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.content-editor::v-deep {
    background-color: $main-background;
    border-radius: 4px 4px 0 0;
    position: relative;

    .ql-container {
        .ql-editor {
            min-height: 20em;
            font-size: 18px;
            font-weight: 400;
            color: $secondary-color;
            line-height: 28.8px;
            overflow-y: visible;
            border-bottom: 2px solid $mercury-solid;

            h1,
            h2,
            h3 {
                padding-top: 0.375em;
                padding-bottom: 0.375em;
            }

            ul,
            ol {
                margin-bottom: 0.75em;
            }

            p {
                margin-bottom: 0.75em;

                .first-person-error {
                    color: white !important;
                    padding: 0 4px;
                    border-radius: 4px;
                    background-color: $primary-color;
                }
            }

            &:after {
                bottom: -1px;
                content: '';
                left: 0;
                position: absolute;
                transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
                width: 100%;
                background-color: $secondary-color-light;
                border-color: $secondary-color-light;
                border-style: solid;
                border-width: thin 0;
                transform: scaleX(0);
                height: 1px;
            }

            &:focus {
                border-bottom: unset !important;

                &:after {
                    transform: scaleX(1);
                    background-color: $secondary-color-light;
                    border-color: $secondary-color-light;
                }
            }

            &.ql-blank::before {
                color: $secondary-color-light;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
            }
        }

        .ql-tooltip {
            width: auto !important;
            z-index: 1000;

            .ql-tooltip-editor {
                width: 350px !important;
            }
        }

        .ql-clipboard {
            position: fixed;
        }

        &.ql-bubble:not(.ql-disabled) a {
            white-space: pre-wrap;
        }

        &.ql-bubble:not(.ql-disabled) a::before,
        &.ql-bubble:not(.ql-disabled) a::after {
            z-index: 1000 !important;
            max-width: 640px !important;
        }

        &.ql-bubble:not(.ql-disabled) a:hover::before,
        &.ql-bubble:not(.ql-disabled) a:hover::after {
            visibility: var(--qlLinkPreviewVisibility, 'visible');
        }
    }

    &.has-error {
        .ql-container {
            .ql-editor {
                &:after {
                    transform: scaleX(1);
                    background-color: $error;
                    border-color: $error;
                }
            }
        }
    }

    &.content-editor-short {
        .ql-container {
            .ql-editor {
                min-height: 10em;
            }
        }
    }

    &.content-editor-one-line {
        .ql-container {
            .ql-editor {
                min-height: 1em;
            }
        }
    }
}

@media all and (max-width: 960px) {
    .ql-container.ql-bubble:not(.ql-disabled) a::before,
    .ql-container.ql-bubble:not(.ql-disabled) a::after {
        z-index: 1000;
        max-width: 290px !important;
        margin-left: 105%;
    }
}
